body {
    overflow-x: hidden;
  }
  

.eleviq__video {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    background-color: var(--color-bg);
    margin-bottom: 0;
  }
  
  .eleviq__video .react-player {
  }

@media screen and (max-width: 1200px){
}

@media screen and (max-width: 1050px){
}

@media screen and (max-width: 550px){
}

