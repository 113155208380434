/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #F5DCA2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: black;
}


.App-link {
  color: #4c8bf5;
  font-size: calc(6px + 2vmin);
}

*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg)
}

a {
  color: unset;
  text-decoration: none;
}

.section__padding {
  padding: 3rem 4.5rem;
}

.section__margin {
  margin: 3rem 4.5rem;
}


@media screen and (max-width: 700px) {
  .section__padding {
    padding: 3rem;
  }

  .section__margin {
    
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 3rem 1.5rem;
  }

  .section__margin {
    margin-left: 1.5rem;
    margin-right: auto;
  
  }  
}
