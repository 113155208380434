.eleviq__header{
    display: flex;
    flex-direction: row;
}

.eleviq__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.eleviq__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 68px;
    letter-spacing: -0.04em;
}

.eleviq__header-content p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.eleviq__header-content__input{
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.eleviq__header-content__input input{
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-size: 19px;
    line-height: 27px;
    background: var(--color-textbox);
    border: 2px solid #eeeeee;
    padding: 0 1rem;
    outline: none;
    color: #333333;
    
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.eleviq__header-content__input button{
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    background: var(--color-button);
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.eleviq__header-content__input-feedback {
    line-height: 0px;
}


.eleviq_header-image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.eleviq__header-image img{
    width: 100%;
    height: 100%;
    
    max-width: 45vw;
    max-height: 45vw;
    
}

@media screen and (max-width: 1050px){
    .eleviq__header{
        flex-direction: column;
    }

    .eleviq__header-content{
        margin: 0rem 0 3rem;
    }

    .eleviq__header-image img{
        max-width: 100%;
        max-height: 100%;
    }
}

@media screen and (max-width: 650px){
    .eleviq__header h1{
        font-size: 38px;
        line-height: 54px;
    }

    .eleviq__header p{
        font-size: 18px;
        line-height: 26px;
    }


    .eleviq__header-content__input input,
    .eleviq__header-content__input button{
        font-size: 18px;
        line-height: 26px;
    }

    
}

@media screen and (max-width: 490px){
    .eleviq__header h1{
        font-size: 30px;
        line-height: 42px;
    }

    .eleviq__header p{
        font-size: 14px;
        line-height: 24px;
    }


    .eleviq__header-content__input input,
    .eleviq__header-content__input button{
        font-size: 12px;
        line-height: 18px;
    }

}
