.eleviq__course{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
}

.eleviq__course-feature{
    display: flex;
}

.eleviq__course-feature .eleviq__features-container__feature{
    margin: 0;
}

.eleviq__course-feature .eleviq__features-container__feature-text{
    max-width: 700px;
}

.eleviq__course-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: var(--color-subtext);

    margin: 4rem 0 2rem;
}

.eleviq__course-heading h1{
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    max-width: 510px;
    font-family: var(--font-family);
}

.eleviq__course-heading p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
}

.eleviq__course-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.eleviq__course-container .eleviq__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.eleviq__course-container .eleviq__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .eleviq__course-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .eleviq__course-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .eleviq__course-feature .eleviq__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .eleviq__course-feature .eleviq__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .eleviq__course-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .eleviq__coursept3-container .eleviq__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}