.eleviq__learn {
    display: flex;
    flex-direction: row;
    background: var(--color-bg-dark);
}

.eleviq__learn-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-left: 2rem;
}

.eleviq__learn-image img {
    width: 100%;
    height: 100%;
}

.eleviq__learn-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

.eleviq__learn-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
    

}

.eleviq__learn-content h4:last-child {
    color: #ff8A71;
    
}

.eleviq__learn-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
    color: var(--color-text-light);
}

.eleviq__learn-content p {
    color: var(--color-text-light);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .eleviq__learn {
        flex-direction: column;
    }

    .eleviq__learn-image {
        margin: 0rem 0;
    }

    .eleviq__learn-image img {
        width: 100%;
        height: 100%;
    }

    .eleviq__learn-content {
        margin-top: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 700px) {
    .eleviq__learn-image img {
        width: 100%;
        height: 100%;
    }
}