@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
/*

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*/

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-nav: #efd9b9;
  --color-bg: #efd9b9;
  --color-bg-dark: #222222;
  --color-footer : #1c427a;
  --color-blue: #1c427a;
  --color-footer-real : #ffffff;
  --color-textbox-light: #ffffff;
  --color-blog: #042c54;
  --color-text: #333333;
  --color-text-dark: #000000;
  --color-text-light: #dddddd;
  --color-subtext: #FF8A71;
  --color-button: #203a77;
  --color-textbox: #eeeeee
}




:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #F5DCA2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: black;
}


.App-link {
  color: #4c8bf5;
  font-size: calc(6px + 2vmin);
}

*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg)
}

a {
  color: unset;
  text-decoration: none;
}

.section__padding {
  padding: 3rem 4.5rem;
}

.section__margin {
  margin: 3rem 4.5rem;
}


@media screen and (max-width: 700px) {
  .section__padding {
    padding: 3rem;
  }

  .section__margin {
    
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 3rem 1.5rem;
  }

  .section__margin {
    margin-left: 1.5rem;
    margin-right: auto;
  
  }  
}


.eleviq__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.eleviq__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.eleviq__cta-content p {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
}

.eleviq__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.eleviq__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.eleviq__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .eleviq__cta {
        flex-direction: column;
    }

    .eleviq__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .eleviq__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .eleviq__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .eleviq__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

.eleviq__cta-content__input{
    width: 100%;
    margin: 1rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.eleviq__cta-content__input input{
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-size: 19px;
    line-height: 27px;
    background: var(--color-textbox);
    border: 2px solid #eeeeee;
    padding: 0 1rem;
    outline: none;
    color: #333333;
    
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.eleviq__cta-content__input button{
    flex: 0.6 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    background: var(--color-button);
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.eleviq__cta-content__input-feedback {
    
}
.eleviq__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.eleviq__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.eleviq__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.04em;
}

.eleviq__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.eleviq__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.eleviq__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (max-width: 550px) {
    .eleviq__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .eleviq__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .eleviq__features-container__feature {
        margin: 1rem 0;
    }
}
.eleviq__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-nav);

    padding: 1.5rem 2rem;
}

.eleviq__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.eleviq__navbar-links_logo {
    margin-right: 2rem;
}

.eleviq__navbar-links_logo img{
    width: 200px;
}

.eleviq__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.eleviq__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.eleviq__navbar-links_container p,
.eleviq__navbar-sign p,
.eleviq__navbar-menu_container p{
    color: #333333;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    
    margin: 0 1rem;
    cursor: pointer;
}

.eleviq__navbar-sign button,
.eleviq__navbar-menu_container button{
    padding: 0.75rem 1.25rem;
    color: #ffffff;
    background: var(--color-button);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.eleviq__navbar-menu{
    margin-left : 1rem;

    display: none;
    position: relative;
}

.eleviq__navbar-menu svg{
    cursor: pointer;
}

.eleviq__navbar-menu-container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-nav);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.eleviq__navbar-menu-container p{
    margin: 1rem 0;
}

.eleviq__navbar-menu_container-links-sign{
    display: none;
}

@media screen and (max-width: 1050px){
    .eleviq__navbar-links_container {
        display:none;
    }

    .eleviq__navbar-menu{
        display: flex;
    }
}

@media screen and (max-width: 700px){
    .eleviq__navbar{
        padding: 2rem, 4rem;
    }
}

@media screen and (max-width: 550px){
    .eleviq__narbar{
        padding: 2rem;
    }

    .eleviq__navbar-sign{
        display: none;
    }

    .eleviq__navbar-menu_container {
        top: 20px;
    }

    .eleviq__navbar-menu_container-links-sign{
        display: block;
    }
}

.eleviq__course{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
}

.eleviq__course-feature{
    display: flex;
}

.eleviq__course-feature .eleviq__features-container__feature{
    margin: 0;
}

.eleviq__course-feature .eleviq__features-container__feature-text{
    max-width: 700px;
}

.eleviq__course-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: var(--color-subtext);

    margin: 4rem 0 2rem;
}

.eleviq__course-heading h1{
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    max-width: 510px;
    font-family: var(--font-family);
}

.eleviq__course-heading p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
}

.eleviq__course-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.eleviq__course-container .eleviq__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.eleviq__course-container .eleviq__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .eleviq__course-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .eleviq__course-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .eleviq__course-feature .eleviq__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .eleviq__course-feature .eleviq__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .eleviq__course-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .eleviq__coursept3-container .eleviq__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer-real);
}

.gpt3__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.gpt3__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid var(--color-text-dark);
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}

.gpt3__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: var(--color-text-dark);
    word-spacing: 2px;
}

.gpt3__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.gpt3__footer-links div {
    width: 250px;
    margin: 1rem;
}

.gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-links_logo img {
    width: 118px;
    height: 30px;

    margin-bottom: 1rem;
}

.gpt3__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: var(--color-text-dark);
}

.gpt3__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.gpt3__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: var(--color-text-dark);

    margin-bottom: 0.9rem;
}

.gpt3__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: var(--color-text-dark);

    margin: 0.5rem 0;
    cursor: pointer;
}

.gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.gpt3__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: var(--color-text-dark);
}

@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
        margin: 1rem 0;
    }

    .gpt3__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
.eleviq__header{
    display: flex;
    flex-direction: row;
}

.eleviq__header-content{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.eleviq__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 68px;
    letter-spacing: -0.04em;
}

.eleviq__header-content p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.eleviq__header-content__input{
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.eleviq__header-content__input input{
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-size: 19px;
    line-height: 27px;
    background: var(--color-textbox);
    border: 2px solid #eeeeee;
    padding: 0 1rem;
    outline: none;
    color: #333333;
    
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.eleviq__header-content__input button{
    flex: 0.6 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    background: var(--color-button);
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.eleviq__header-content__input-feedback {
    line-height: 0px;
}


.eleviq_header-image{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.eleviq__header-image img{
    width: 100%;
    height: 100%;
    
    max-width: 45vw;
    max-height: 45vw;
    
}

@media screen and (max-width: 1050px){
    .eleviq__header{
        flex-direction: column;
    }

    .eleviq__header-content{
        margin: 0rem 0 3rem;
    }

    .eleviq__header-image img{
        max-width: 100%;
        max-height: 100%;
    }
}

@media screen and (max-width: 650px){
    .eleviq__header h1{
        font-size: 38px;
        line-height: 54px;
    }

    .eleviq__header p{
        font-size: 18px;
        line-height: 26px;
    }


    .eleviq__header-content__input input,
    .eleviq__header-content__input button{
        font-size: 18px;
        line-height: 26px;
    }

    
}

@media screen and (max-width: 490px){
    .eleviq__header h1{
        font-size: 30px;
        line-height: 42px;
    }

    .eleviq__header p{
        font-size: 14px;
        line-height: 24px;
    }


    .eleviq__header-content__input input,
    .eleviq__header-content__input button{
        font-size: 12px;
        line-height: 18px;
    }

}

.eleviq__gpt {
    display: flex;
    flex-direction: row;
    background: var(--color-bg-dark);
    
}

.eleviq__gpt-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.eleviq__gpt-image img {
    width: 100%;
    height: 100%;
}

.eleviq__gpt-content {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 1rem;
}

.eleviq__gpt-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.eleviq__gpt-content h4:last-child {
    color: #ff8A71;
    margin-bottom: 0rem;
}

.eleviq__gpt-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
    color: var(--color-text-light);
}

.eleviq__gpt-content p {
    color: var(--color-text-light);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .eleviq__gpt {
        flex-direction: column;
    }

    .eleviq__gpt-image {
        margin: 0rem 0;
    }

    .eleviq__gpt-image img {
        width: 100%;
        height: 100%;
    }

    .eleviq__gpt-content {
        margin-top: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 700px) {
    .eleviq__gpt-image img {
        width: 100%;
        height: 100%;
    }
}

.eleviq__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    
}

.eleviq__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.eleviq__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    color: var(--color-text);
}

.eleviq__features-heading p {
    color: var(--color-text);
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.eleviq__features-container {
    color: #333333;
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;   
}


@media screen and (max-width: 990px) {
    .eleviq__features {
        flex-direction: column;
    }

    .eleviq__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .eleviq__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}
.eleviq__learn {
    display: flex;
    flex-direction: row;
    background: var(--color-bg-dark);
}

.eleviq__learn-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-left: 2rem;
}

.eleviq__learn-image img {
    width: 100%;
    height: 100%;
}

.eleviq__learn-content {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

.eleviq__learn-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
    

}

.eleviq__learn-content h4:last-child {
    color: #ff8A71;
    
}

.eleviq__learn-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
    color: var(--color-text-light);
}

.eleviq__learn-content p {
    color: var(--color-text-light);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .eleviq__learn {
        flex-direction: column;
    }

    .eleviq__learn-image {
        margin: 0rem 0;
    }

    .eleviq__learn-image img {
        width: 100%;
        height: 100%;
    }

    .eleviq__learn-content {
        margin-top: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 700px) {
    .eleviq__learn-image img {
        width: 100%;
        height: 100%;
    }
}
body {
    overflow-x: hidden;
  }
  

.eleviq__video {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    background-color: var(--color-bg);
    margin-bottom: 0;
  }
  
  .eleviq__video .react-player {
  }

@media screen and (max-width: 1200px){
}

@media screen and (max-width: 1050px){
}

@media screen and (max-width: 550px){
}


.eleviq__ {
    display: grid;
    grid-template-columns: 65% 35%; /* Adjust the column widths */
}
  .eleviq__courseinfo {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    background: var(--color-bg);
    margin-top: 2rem;
    width: 85%; /* Adjust the width of the courseinfo box */
    margin-right: 0;
}
  
.eleviq__buybox {

    font-family: var(--font-family);
    background: var(--color-blue); /* Customize the background color */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: -webkit-sticky;
    position: sticky;
    max-height: 20rem;
    max-width: 20rem;

    /* position: fixed;
    top: 62%; /* Adjust the top position as needed 
    right: 0rem; /* Adjust the right position as needed 
    transform: translateY(-50%); /* Vertically center the box */ 
    
    
    max-height: 20rem;
    max-width: 20rem;

    
    border-radius: 7px;

    margin-right: 0rem;
}

.eleviq__buybox h2{
    font-size: 36px;
    text-align: center;
    font-weight: 500;
    color: var(--color-text-light);
}


.eleviq__buybox-content-60{
    font-size: 36px;
    font-weight: 700;
    color: var(--color-text-light);
}

.eleviq__buybox h1{
    font-size: 58px;
    text-align: center;
    font-weight: 700;
    color: var(--color-subtext);
}

.eleviq__buybox p{
    font-size: 16px;
    text-align: center;
    font-weight: 450;
    color: var(--color-text-light);
}

.eleviq__buybox-content__enroll {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
  

.eleviq__buybox-content__enroll button{
    padding: 0.75rem 0.75rem;
    color: #ffffff;
    background: var(--color-subtext);
    font-family: var(--font-family);
    line-height: 25px;
    border-radius: 5px;
    outline: var(--color-text-light);
    cursor: pointer;

    margin-bottom: 0.75rem;
    
}


.eleviq__buyboxlower {
    background: var(--color-blue); /* Customize the background color */
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: -webkit-sticky;
    position: sticky;
    max-height: 20rem;
    max-width: 20rem;

    border-radius: 7px;
}

.eleviq__buyboxlower h2{
    font-size: 36px;
    text-align: center;
    font-weight: 500;
    color: var(--color-text-light);
}


.eleviq__buyboxlower-content-60{
    font-size: 36px;
    font-weight: 700;
    color: var(--color-text-light);
}

.eleviq__buyboxlower h1{
    font-size: 58px;
    text-align: center;
    font-weight: 700;
    color: var(--color-subtext);
}

.eleviq__buyboxlower p{
    font-size: 16px;
    text-align: center;
    font-weight: 450;
    color: var(--color-text-light);
}

.eleviq__buyboxlower-content__enroll {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
  

.eleviq__buyboxlower-content__enroll button{
    padding: 0.75rem 0.75rem;
    color: #ffffff;
    background: var(--color-subtext);
    font-family: var(--font-family);
    line-height: 25px;
    border-radius: 5px;
    outline: var(--color-text-light);
    cursor: pointer;

    
}

.eleviq__courseinfo-container{
    font-family: var(--font-family);
    
}

.eleviq__courseinfo-container h1{
    margin: 2rem 0rem 1rem 4rem;
    font-size: 30px;
    font-weight:600;
}

.eleviq__courseinfo-container h2{
    margin: 2rem 0rem 1rem 4rem;
    font-size: 26px;
    font-weight:500;
}

.eleviq__courseinfo-container h3{
    margin: 0rem 0rem 1rem 4rem;
    font-size: 20px;
}

.eleviq__courseinfo-container h4{
    margin: 0rem 0rem 1rem 4rem;
    font-size: 16px;
    font-weight: 800;
    flex-direction: row;
}

.eleviq__courseinfo-container p{
    margin: 0rem 2rem 1rem 4rem;
    font-size: 16px;
}

.eleviq__courseinfo-menu h2{
    font-family: var(--font-family);
}

.eleviq__courseinfo-learningoutcomes1{
    border: 3px solid var(--color-text);
    margin: 4rem 0rem 4rem 4rem;
}

.eleviq__courseinfo-learningoutcomes2{
    border: 3px solid var(--color-text);
    margin: 4rem 0rem 4rem 4rem;
}

.eleviq__courseinfo-learningoutcomes2-dropdown1{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.eleviq__courseinfo-learningoutcomes2-part1{
    float: left;
    align-items: center;
}


.eleviq__courseinfo-learningoutcomes2 p{
    line-height: 36px;
    margin: 0px;
}

.eleviq__courseinfo-learningoutcomes2 h3{
    margin: 0px;
}

.eleviq__courseinfo-learningoutcomes2-accordion{
    margin: 2rem;
    
}


.eleviq__courseinfo-menu{
    margin-left : 2rem;
    margin-right: 0rem;

    position: relative;
}

.eleviq__courseinfo-menu svg{
    cursor: pointer;
}

.eleviq__courseinfo-menu_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    background: var(--color-textbox-light);
    padding: 1rem;
    top: 40px;
    transform: translateX(-100px); /* Bring it back into view */
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 5px;
  }
  
  

.eleviq__courseinfo-menu_container p{
}

.eleviq__courseinfo-menu_container-links p {
    margin-left: 0;
    white-space: nowrap; /* Prevents line breaks */
    overflow: hidden; /* Hides overflowing text */
    text-overflow: ellipsis; /* Truncates text with an ellipsis (...) */
    max-width: 100%; /* Limits the width of the text */
  }
  

.eleviq__courseinfo-menu_container-links-sign{
}

.eleviq__buybox{
    border: 3px solid var(--color-text);
    margin: 4rem 4rem 4rem 0rem;
}


@media screen and (max-width: 1200px) {
    .eleviq__buybox h2{
        font-size: 30px;
    }
    
    
    .eleviq__buybox-content-60{
        font-size: 30px;
    }
    
    .eleviq__buybox h1{
        font-size: 46px;
    }
    
    .eleviq__buybox p{
        font-size: 14px;
    }

    .eleviq__buybox {
        max-height: 18rem;
        max-width: 18rem;
    }

    .eleviq__buybox-content__enroll button{
        margin-top: 0.75rem;
    }
}

@media screen and (max-width: 1050px) {

    .eleviq__courseinfo-learningoutcomes1{
        margin-left: 1rem;
    }

    .eleviq__courseinfo-learningoutcomes2{
        margin-left: 1rem;
    }

    .eleviq__courseinfo-container h1{
        margin: 1rem 0rem 0.5rem 2rem;
    }
    
    .eleviq__courseinfo-container h2{
        margin: 1rem 0rem 0.5rem 2rem;
    }
    
    .eleviq__courseinfo-container h3{
        margin: 0rem 0rem 0.5rem 2rem;
    }
    
    .eleviq__courseinfo-container h4{
        margin: 0rem 0rem 0.5rem 2rem;
    }
    
    .eleviq__courseinfo-container p{
        margin: 0rem 1rem 0.5rem 2rem;
    }

    .eleviq__ {
        display: default;
        grid-template-columns: 100% 0%; /* Adjust the column widths */
    }

    .eleviq__courseinfo{
        width: 92%;
    }

    .eleviq__buybox {
        display: none;
        
    }

    .eleviq__buyboxlower{
        display: flex;
        margin: auto;
        margin-top: 5rem;
    }

    .eleviq__buyboxlower h2{
        font-size: 36px;
        font-weight: 500;
        margin: 0;
    }
    
    
    .eleviq__buyboxlower-content-60{
        font-size: 36px;
        font-weight: 700;
        margin: 0;
    }
    
    .eleviq__buyboxlower h1{
        font-size: 58px;
        font-weight: 700;
        margin: 0;
    }

    .eleviq__buyboxlower p{
        font-size: 16px;
        font-weight: 450;
        margin: 0;
    }
    
    .eleviq__buyboxlower-content__enroll {
        margin: 0;
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    
    
    .eleviq__buyboxlower-content__enroll button{
        padding: 0.75rem 0.75rem;
        color: #ffffff;
        background: var(--color-subtext);
        font-family: var(--font-family);
        line-height: 25px;
        border-radius: 5px;
        outline: var(--color-text-light);
        cursor: pointer;
        margin-bottom: 1rem;
    
        
    }
}


@media screen and (max-width: 550px) {

    .eleviq__section__margin{
    }

    .eleviq__courseinfo-container h1{
        margin: 1rem 0rem 0.5rem 1rem;
        font-size: 26px;
        font-weight:500;
    }
    
    .eleviq__courseinfo-container h2{
        margin: 1rem 0rem 0.5rem 1rem;
        font-size: 22px;
        font-weight:450;
    }
    
    .eleviq__courseinfo-container h3{
        margin: 0rem 0rem 0.5rem 1rem;
        font-size: 18px;
    }
    
    .eleviq__courseinfo-container h4{
        margin: 0rem 0rem 0.5rem 1rem;
        font-size: 13.5px;
        font-weight: 700;
        flex-direction: row;
    }
    
    .eleviq__courseinfo-container p{
        margin: 0rem 1rem 0.5rem 2rem;
        font-size: 13.5px;
    }

    .eleviq__buyboxlower h2{
        font-size: 31px;
        margin: 0;
    }
    
    
    .eleviq__buyboxlower-content-60{
        font-size: 28px;
        margin: 0;
    }
    
    .eleviq__buyboxlower h1{
        font-size: 42px;
        margin: 0;
    }
    
    .eleviq__buyboxlower p{
        font-size: 12px;
        margin: 0;
    }

    .eleviq__buyboxlower {
        max-height: 16rem;
        max-width: 16rem;
    }

    .eleviq__buyboxlower-content__enroll button{
        margin-top: 0rem;
    }

    .eleviq__courseinfo-learningoutcomes1{
        margin-top: 3rem;
        margin-left: 0rem;
    }

    .eleviq__courseinfo-learningoutcomes2{
        margin-left: 0rem;
    }

    .eleviq__{
        margin-top: 0;
    }

    .eleviq__courseinfo-learningoutcomes2 p{
        line-height: 26px;
    }
}


.hero-container {
    text-align: center;
    padding: 50px 20px;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }
  
